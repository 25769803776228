<!--
 * @Description: 
 * @Author: JunLiangWang
 * @Date: 2023-03-30 22:24:00
 * @LastEditors: JunLiangWang
 * @LastEditTime: 2023-10-17 11:05:42
-->
<template>
  <main class="mian">
    <div class="project-container">
      <header>
        <div class="cover">
          <img :src="bookData[$route.query.bookIndex].cover" />
          <span class="book-cover-border" />
        </div>
        <aside class="content">
          <div>
            <div class="title">
              <span>{{ bookData[$route.query.bookIndex].title }}</span>
              <el-button
                class="button"
                size="mini"
                icon="el-icon-caret-left"
                round
                @click="$router.go(-1)"
                >返回</el-button
              >
            </div>
            <p class="author">{{ bookData[$route.query.bookIndex].author }}</p>
          </div>
          <p class="intro">{{ bookData[$route.query.bookIndex].intro }}</p>
        </aside>
      </header>
      <div class="btn-list">
        <div
          v-bind:class="[{ active: activeIndex == 0 }, 'btn']"
          @click="activeIndex = 0"
        >
          书评
        </div>
        <div
          v-bind:class="[{ active: activeIndex == 1 }, 'btn']"
          @click="activeIndex = 1"
        >
          笔记
        </div>
      </div>
      <div
        v-show="activeIndex == 0"
        v-html="bookData[$route.query.bookIndex].bookReview.replaceAll('\n','<br>')"
      ></div>
      <p
        class="none"
        v-show="
          activeIndex == 0 && bookData[$route.query.bookIndex].bookReview == ''
        "
      >
        暂无书评
      </p>
      <div v-show="activeIndex == 1">
        <div
          class="comment-container"
          v-for="(item, index) in bookData[$route.query.bookIndex].reviews"
          :key="index"
        >
          <div style="position: relative">
            <i class="iconfont iconyinhao- quotation-mark" />
            <p class="quotation-sentence special-p" v-html="item.abstract"></p>
            <i class="iconfont iconyinhao- quotation-mark-invert" />
          </div>
          <p class="special-p comment">{{ item.content }}</p>
          <p class="special-p comment comment-time">{{ dateToComm(item.createTime) }}</p>
        </div>
      </div>
      <p
        class="none"
        v-show="
          activeIndex == 1 &&
          bookData[$route.query.bookIndex].reviews.length == 0
        "
      >
        暂无笔记
      </p>
    </div>
  </main>
</template>

<script>
import bookData from "@/config/book/index.js";
export default {
  name: "bookDetail",
  data() {
    return {
      bookData,
      activeIndex: 0,
    };
  },
  methods: {
    dateToComm(val) {
      var datetime=new Date(val*1000);
      var year = datetime.getFullYear();
      var month = datetime.getMonth() + 1; //js从0开始取
      var date = datetime.getDate();
      var hour = datetime.getHours();
      var minutes = datetime.getMinutes();
      var second = datetime.getSeconds();

      if (month < 10) {
        month = "0" + month;
      }
      if (date < 10) {
        date = "0" + date;
      }
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (second < 10) {
        second = "0" + second;
      }
      return year + "年" + month + "月" + date + "日 "+hour + "时" + minutes + "分";
    },
  },
};
</script>
<style lang="scss" scoped>
main {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
}

.project-container {
  max-width: 980px;
  width: 100%;
  font-size: 16px;
  margin: 20px auto;
  padding: 0 25px;
  box-sizing: border-box;
}

header {
  display: flex;
}

.cover {
  position: relative;
  line-height: 0;
  margin-right: 25px;

  img {
    width: 150px;
  }
}

.book-cover-border {
  background: transparent;
  background-image: linear-gradient(
    90deg,
    hsla(0, 0%, 63.1%, 0.25),
    rgba(21, 21, 20, 0.1) 1%,
    hsla(0, 0%, 100%, 0.15) 4%,
    hsla(0, 0%, 58%, 0.1) 8%,
    hsla(0, 0%, 89%, 0) 57%,
    rgba(223, 218, 218, 0.03) 91%,
    rgba(223, 218, 218, 0.05) 98%,
    hsla(0, 0%, 100%, 0.1)
  );
  box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  .title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .button {
      margin-left: 5px;
    }
    span {
      font-size: 1.3em;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }

  .author {
    color: #2196f3;
    font-size: 1em;
    margin-top: 10px;
  }

  .intro {
    margin: 0;
    opacity: 0.5;
    font-size: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
  }
}

.btn-list {
  margin: 36px 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  max-width: 350px;
}

.btn {
  width: 100%;
  padding: 5px 0;
  border-radius: 20px;
  background: #eaeff2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9b9b9b;
  cursor: pointer;
}

.btn:hover {
  color: white;
  background: #64b5f6;
}

.btn:first-child {
  margin-right: 20px;
}

.btn.active {
  color: white;
  background: #0969da;
}
.none {
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  opacity: 0.2;
  letter-spacing: 10px;
  margin: 200px 0px;
}
.quotation-mark {
  top: 0;
  left: 0;
  position: absolute;
}
.quotation-mark-invert {
  display: inline-block;
  transform: rotate(180deg);
  position: absolute;
  right: 0;
  bottom: 0;
}
.quotation-sentence {
  text-align: justify;
  padding: 10px 2.19em 8px 2.19em;
}
.special-p {
  margin: 0;
}
.comment-container {
  padding: 3.12em 0;
  border-top: solid 1.5px #ebedf1;
}
.comment-container:first-of-type {
  border-top: none;
  padding-top: 10px;
}
.comment {
  padding: 0 1.87em;
  opacity: 0.5;
  margin-top: 20px;
}
.comment-time {
  text-align: right;
  margin-top: 10px;
}
@media only screen and (max-width: 400px) {
  .project-container {
    font-size: 3.5vw;
  }
}
</style>
<style lang="scss">
.button {
  span,
  i {
    background: transparent;
  }
}
</style>